import {CategoryModel} from "@coremodels";
import {AccessoryProfileModel} from "./accessory-profile.model";
import {AccessoryTypeModel} from "./accessory-type.model";
import {EdgeModel} from "./edge.model";
import {FamilyModel} from "./family.model";
import {FinishModel} from "./finish.model";
import {FormatModel} from "./format.model";
import {MainColorModel} from "./main-color.model";
import {MaterialModel} from "./material.model";
import {ProductAccessoriesModel} from "./product-accessories.model";
import {ProductImageModel} from "./product-image.model";
import {ProductMakerMarksIconsModel} from "./product-maker-marks-icons.model";
import {ProductRecommendedColorsModel} from "./product-recommended-colors.model";
import {ProductResourcesModel} from "./product-resources.model";
import {ProductSpeficationIconsModel} from "./product-spefication-icons.model";
import {SubColorModel} from "./sub-color.model";
import {SubNameModel} from "./sub-name.model";
import {UnitOfMeasurementModel} from "./unit-of-measurement.model";

export class ProductModel {
    accessory_profile_id: any;
    apply_glue_calculator: any;
    blog_link: any;
    created_at: any;
    edge_id: any;
    edge_name: any;
    family_id: any;
    family_name: any;
    finish_id: any;
    format_id: any;
    format_name: any;
    full_description: any;
    hero_finish: any;
    hero_format_sku: any;
    hero_sku: any;
    id: any;
    is_available: any;
    is_new: any;
    last_ordered: any;
    last_quoted: any;
    lead_time: any;
    length: any;
    main_color_id: any;
    main_sample_sku: any;
    material_id: any;
    modified_at: any;
    moi: any;
    moq: any;
    next_stock_arrival: any;
    qr_code_link: any;
    release_date: any;
    sealer_calculator: any;
    secondary_sample_sku: any;
    short_description: any;
    sku: any;
    slip_rating: any;
    status: any;
    sub_color_id: any;
    texture_guide: any;
    thickness: any;
    title: any;
    type: any;
    unit_of_measurment_id: any;
    video_link: any;
    website_url: any;
    width: any;
    size: any;
    height: any;
    diameter: any;
    opening: any;
    weight: any;
    video_url: any;
    blog_url: any;
    finish_name: any;
    sub_name: any;
    category_name: any;
    dimensions: any;
    material_name: any;
    unit_of_measurement_name: any;
    is_feature: any;
    is_all_in_one: any;
    formatHeroImage: any;
    subname_name: any;
    category: any;
    stock: any;
    material?: MaterialModel[];
    format?: FormatModel;
    finish?: FinishModel;
    edge?: EdgeModel;
    price: any;
    accessoryProfile?: AccessoryProfileModel;
    accesoryType?: AccessoryTypeModel;
    family?: FamilyModel;
    subname?: SubNameModel;
    mainColor?: MainColorModel;
    subColor?: SubColorModel;
    unitOfMeasurement?: UnitOfMeasurementModel;
    productAccessories?: ProductAccessoriesModel;
    productImages?: ProductImageModel[];
    productMakerMarksIcons?: ProductMakerMarksIconsModel[];
    productRecommendedColors?: ProductRecommendedColorsModel[];
    productResources?: ProductResourcesModel[];
    productSpecificationIcons?: ProductSpeficationIconsModel[];
    availableFinishes?: FinishModel[];
    productProjectImagery?: ProductImageModel[];
    finishImage?: ProductImageModel;
    formatImage?: ProductImageModel;
    seamlessTextureImages?: ProductImageModel[];
    productResourcesDocument?: ProductResourcesModel[];
    productInstallationResources?: ProductResourcesModel[];
    availableFormats!: FormatModel[];
    availableFamily?: FamilyModel[];
    availableMaterial?: MaterialModel[];
    is_favorite?: any;
    finish_edge_name: any;
    quantity: any;
    is_feature_profile: any;
    category_id: any;
    customDimension?: any;
    availableCategories?: CategoryModel[];
    calculatedLeadTime?: any;
    availableThickness?: any;
    availableEdge?: any;
    availableSize?: any;
    is_tooltip_display?: any;
    tooltip_placement?: any;
    packed_quantity: any;
    main_sample_moi?: any;
    main_sample_finish_edge_name?: any;
    main_sample_length ?: any
    main_sample_width ?: any
    main_sample_thickness ?: any
    main_sample_short_description ?: any
    required_quantity: any;
    main_sample_sku_price: any;
}
