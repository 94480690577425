<div class="error-page-background">
  <header>
    <div class="header-main darkHeader">
      <div class="container-fluid">
        <div class="header-item-container">
          <div class="logo error-page-logo">
            <a href="javascript:void(0)" (click)="onHeader()">
<!--              <img src="{{s3BucketImageURL}}/logo.svg" alt="Logo">-->
              <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/logo.svg" alt="Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <main class="error-page-padding">
    <div class="container">
      <div class="error-main">
        <h1 class="m-0">404</h1>
        <h6>Page not found</h6>
      </div>
    </div>
  </main>
  <footer>
    <div class="footer-bottom">
      <div class="row align-items-center">
        <div class="col-xl-4 col-ld-4 col-md-4 col-sm-12 col-12">
          <div class="powered-by">
            <ul>
              <li>
                <div class="powered-by-logo">
<!--                  <img src="{{s3BucketImageURL}}/stripe.svg" alt="Stripe" />-->
                  <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/stripe.svg" alt="Stripe" />
                </div>
              </li>
              <li>
                <div class="powered-by-logo">
<!--                  <img src="{{s3BucketImageURL}}/poli.svg" alt="poli" />-->
                  <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/poli.svg" alt="poli" />
                </div>
              </li>
              <li>
                <div class="powered-by-logo">
<!--                  <img src="{{s3BucketImageURL}}/zip.svg" alt="zip" />-->
                  <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/zip.svg" alt="zip" />
                </div>
              </li>
              <li>
                <div class="powered-by-logo">
<!--                  <img src="{{s3BucketImageURL}}/visa.svg" alt="visa" />-->
                  <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/visa.svg" alt="visa" />
                </div>
              </li>
              <li>
                <div class="powered-by-logo">
<!--                  <img src="{{s3BucketImageURL}}/master.svg" alt="master" />-->
                  <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/master.svg" alt="master" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-ld-4 col-md-4 col-sm-12 col-12">
          <div class="smile-logo">
            <a>
<!--              [routerLink]="['/tiles-for-smiles']"-->
<!--              <img src="{{s3BucketImageURL}}/smiles.png" alt="Smiles" />-->
              <img src="https://ik.imagekit.io/plc5pl8np/WEBSITE_ASSET_STAGING/smiles.png" alt="Smiles" />
              <p>Goods for the Greater Good.</p>
            </a>
          </div>
        </div>
        <div class="col-xl-4 col-ld-4 col-md-4 col-sm-12 col-12">
          <div class="copyright">
            <p>
              © {{ currentYear }} Gather Co. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
